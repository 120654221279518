.carousel .slide {
  background: transparent;
  margin-bottom: 24px;
}

.slide div {
  background-color: transparent;
}

.carousel .control-dots {
  padding: 0;
  bottom: -6px !important;
}

.carousel .control-dots .dot {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  width: 4px;
  height: 4px;
  margin: 0 4px;
}

@media (prefers-color-scheme: dark) {
  .carousel .control-dots .dot {
    -webkit-filter: invert(100%);
            filter: invert(100%);
  }
}

.MuiModal-root {
  overflow-y: scroll !important;
}

.carousel .arrow {
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1300;
}

.carousel .arrow.hide {
  opacity: 0;
}

.carousel .arrow.left {
  left: 10%;
}

.carousel .arrow.right {
  right: 10%;
}

.carousel .arrow.android.left {
  left: 8%;
}

.carousel .arrow.android.right {
  right: 8%;
}

.carousel {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.carousel img {
  width: 60% !important;
}

.carousel__button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 99;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
}

.carousel__button.show {
  opacity: 1;
}

.carousel__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.carousel__button--left {
  left: 48px;
}

.carousel__button--right {
  right: 48px;
}

.images {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 16px;
  justify-content: center;
}

.carousel__image {
  border-radius: 6px;
}

.carousel__image--current {
  width: 500px;
  height: 210px;
  background-size: cover;
  cursor: pointer;
}

.carousel__image--prev {
  width: calc((100% - 500px) / 2);
  height: 189px;
  background-size: cover;
  border-radius: 6px 0 0 6px;
}

.carousel__image--next {
  width: calc((100% - 500px) / 2);
  height: 189px;
  background-size: cover;
  background-position-x: 100%;
  border-radius: 0 6px 6px 0;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/fonts/proximanova-regular-webfont.woff2") format("woff2"),
    url("/fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: proxima-nova, sans-serif;
}

.type_title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 16px;
}

@media (prefers-color-scheme: dark) {
  #root,
  body,
  html {
    background-color: rgba(55, 56, 58, 1);
  }

  .type_title {
    color: #e8eaed;
  }
}

